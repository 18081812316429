

















import Component from 'vue-class-component';
import Vue from 'vue';
import navigation from '@/components/Navigation.vue';
import UsersStoreGetters from '@/constants/store/modules/users/getters';
import Token from '@/typings/backend/jsonApiTranslations/token';
import CountryStoreActions from '@/constants/store/modules/country/actions';
import GymStoreGetters from '@/constants/store/modules/gyms/getters';
import Gym from '@/typings/backend/jsonApiTranslations/gym';
import GymStoreMutations from '@/constants/store/modules/gyms/mutations';
import GymsStoreActions from '@/constants/store/modules/gyms/actions';
import JsonApiConfiguration from '@/typings/jsonApi/configuration/configuration';
import { AxiosResponse } from 'axios';
import axiosJsonApi from '@/plugins/axios/jsonApi';
import genericAxios from '@/plugins/axios/generic';
import { namespace } from 'vuex-class';
import CheckUserLoggedInService from '@/services/auth/CheckUserLoggedInService';
import UserStoreActions from '@/constants/store/modules/users/actions';
// eslint-disable-next-line import/no-unresolved,import/extensions
import { Route } from 'vue-router/types/router';

const userStoreModule = namespace('users');
const countryStoreModule = namespace('countries');
const gymStoreModule = namespace('gyms');

@Component({
  components: {
    navigation,
  },
})
export default class ContainedLayout extends Vue {
  @userStoreModule.Getter(UsersStoreGetters.GET_ACTIVE_TOKEN) token!: Token;

  @countryStoreModule.Action[CountryStoreActions.GET_COUNTRIES]: Function;

  @gymStoreModule.Getter(GymStoreGetters.GET_GYMS) gyms!: Gym[];

  @gymStoreModule.Mutation [GymStoreMutations.SET_ACTIVE_GYM]: (
    gym: Gym
  ) => void;

  @gymStoreModule.Action [GymsStoreActions.GET_GYMS]: (
    { params }: { params?: JsonApiConfiguration }
  ) => Promise<AxiosResponse<Gym[]>>;

  @userStoreModule.Action[UserStoreActions.INITIALIZE_NECESSARY_DATA]: (
    { route }: { route: Route }
  ) => Promise<void>;

  public dataHasBeenInitialized: boolean = false;

  public CheckUserLoggedInService = new CheckUserLoggedInService();

  public created(): void {
    // Resources that can be cached
    this[CountryStoreActions.GET_COUNTRIES]({});

    this.checkAuthentication();

    if (this.CheckUserLoggedInService.isLoggedIn()) {
      this.setUpLoggedInUser();
    }
  }

  private async checkAuthentication(): Promise<void> {
    const routeNeedsAuthentication = (
      Object.prototype.hasOwnProperty.call(this.$route.meta, 'needsAuthentication')
        ? this.$route.meta.needsAuthentication : true
    );

    if (routeNeedsAuthentication && !this.CheckUserLoggedInService.isLoggedIn()) {
      this.$toasted.show('Please, login to be able to view such page');

      await this.$router.push({
        name: 'login',
      })
        .then(() => {
          this.dataHasBeenInitialized = true;
        });
    }
  }

  private setUpLoggedInUser(): void {
    axiosJsonApi.defaults.headers.Authorization = (
      `${this.token.tokenType} ${this.token.accessToken}`
    );

    genericAxios.defaults.headers.Authorization = (
      `${this.token.tokenType} ${this.token.accessToken}`
    );

    this[UserStoreActions.INITIALIZE_NECESSARY_DATA]({ route: this.$route })
      .then(() => {
        this.dataHasBeenInitialized = true;
      });
  }
}
